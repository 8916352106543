select {
  padding: 2px;
  margin: 6px;
  height: 32px;
  text-align: center;
  background: #f1f1f1;
  border: 1px solid hsla(0, 0%, 31%, 0.718);
  border-radius: 5px;
}

input[type="date"] {
  padding: 2px;
  margin: 6px;
  width: 120px;
  border: 1px solid hsla(0, 0%, 31%, 0.718);
  border-radius: 5px;
}

input[type="text"] {
  width: 125px;
  box-sizing: border-box;
  margin: 6px;
  padding: 5px;
  display: inline-block;
  border: 1px solid hsla(0, 0%, 31%, 0.718);
  border-radius: 5px;
}

input[type="text"]:focus {
  background-color: #ddd;
  outline: none;
}

.fipDetails {
  text-align: left;
  margin-left: 36%;
}

/* Set a style for the submit/register button */
.registerbtn {
  background-color: #13397f;
  color: #ffffff;
  padding: 9px 16px;
  margin: 8px 0;
  font: 17px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid;
  cursor: pointer;
  border-radius: 10px;
}

.reload-btn {
  background-color: #13397f;
  color: #ffffff;
  padding: 5px 16px;
  margin: 8px 0;
  font: 17px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid;
  cursor: pointer;
  border-radius: 10px;
}

.registerbtn:hover,
.reload-btn:hover {
  opacity: 1;
}

.registerbtn:active,
.reload-btn:active {
  transform: scale(0.95);
}

#message,
#manageAccounts {
  margin: 5px;
}

#manageAccounts a {
  color: #345feb;
  text-decoration: none;
}

#manageAccounts a:hover {
  color: #4d4dff;
  text-decoration: underline;
}
